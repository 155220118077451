<template>
  <div id="route">
    <v-row class="page-title px-4">
      <v-col cols="10">
        <h2>
          <span class="me-2">Route {{ route.route_short_name }} - {{ route.route_long_name }}</span>
        </h2>
      </v-col>
      <v-col cols="2">
        <save-button :save-action="saveRouteConfig"></save-button>
      </v-col>
    </v-row>
    <v-divider class="mt-4 mb-10"></v-divider>
    <v-row v-if="!loading">
      <v-col cols="12" md="5" lg="4">
        <route-info-panel />
      </v-col>
      <v-col cols="12" md="7" lg="8">
        <route-overview ref="routeOverview" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiRoutes } from "@mdi/js"
import { mapState } from "vuex"
import { getCurrentInstance } from "@vue/composition-api/dist/vue-composition-api"
import RouteInfoPanel from "./components/RouteInfoPanel.vue"
import RouteOverview from "./components/RouteOverview.vue"
import SaveButton from "@/views/next-stop/components/SaveButton.vue"
import store from "@/store"

export default {
  name: "RouteConfiguration",
  metaInfo() {
    return {
      title: `Edit Route`,
    }
  },
  components: {
    RouteInfoPanel,
    RouteOverview,
    SaveButton,
  },
  computed: {
    ...mapState({ routes: state => state.gtfs.routes, route: state => state.selected_route }),
  },
  created() {
    store.dispatch("getRoute", this.routeId).then(() => {
      this.loading = false
    })
  },
  methods: {
    saveRouteConfig() {
      this.$refs.routeOverview.saveRouteConfig()
    },
  },
  setup() {
    const curr = getCurrentInstance()
    const routeId = curr.proxy.$route.params.id

    if (!routeId) {
      curr.proxy.$router.push({
        name: "next-stop-routes",
      })
    }

    return {
      routeId,
      loading: true,
      icons: {
        mdiRoutes,
      },
    }
  },
}
</script>

<style scoped></style>
