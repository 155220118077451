<template>
  <div id="stop-overview">
    <v-tabs v-model="currentTab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>
          {{ tab.title }}
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-row class="align-right">
              <v-col>
                <v-switch
                  v-model="route_settings.is_enabled"
                  label="Enable next stop announcements?"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>

            <v-row class="align-right">
              <v-col>
                <v-switch
                  v-model="route_settings.announce_last_stop"
                  label="Enable last stop announcement?"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="canNotes">
        <v-card>
          <!-- Notes Filter fields start -->
          <v-row class="pt-4 pb-0 ma-0">
            <v-col cols="12" sm="4">
              <v-select
                v-model="sqPriority"
                :items="priorityItems"
                item-text="text"
                item-value="value"
                label="Priority"
                placeholder="Priority"
                outlined
                hide-details
                clearable
                dense
                class="user-search me-3"
                @change="val => addToSearchHistory(val, 'sqPriority')"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="sqClaimant"
                label="Claimant"
                outlined
                hide-details
                clearable
                persistent-clear
                dense
                class="user-search me-3"
                @change="val => addToSearchHistory(val, 'sqClaimant')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-menu
                v-model="menu"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="sqCreated"
                    label="Date"
                    readonly
                    v-bind="attrs"
                    outlined
                    hide-details
                    dense
                    clearable
                    class="user-search me-3"
                    v-on="on"
                    @change="val => addToSearchHistory(val, 'sqCreated')"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="sqCreated" no-title @input="menu = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Notes Filter fields end -->
          <!-- Notes Table start -->
          <v-data-table
            :headers="noteHeaders"
            :items="filteredNotes"
            :sort-by.sync="noteSortBy"
            :sort-desc.sync="noteSortDesc"
            :custom-sort="customSort"
            class="font-weight-semibold text--primary"
            :must-sort="true"
          >
            <template v-slot:item.note_id="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.note_id }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.title="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.title }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.priority="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.priority }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.status="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.status }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.claimant="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.claimant }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.convertCreatedDate="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary" v-html="convertCreatedDate(item)"></span>
                  </div>
                </div>
              </router-link>
            </template>
          </v-data-table>
          <!-- Notes Table end -->
          <!-- Add Note Button, Modal and Form start -->
          <v-row class="pt-8 pl-5 pb-6 ma-0">
            <v-btn color="primary" elevation="0" @click="openDialog">Add Note</v-btn>
          </v-row>
          <v-dialog v-model="dialog" max-width="600">
            <v-card>
              <v-card-title class="d-flex">
                <span class="headline flex-grow-1">Add Note</span>
                <v-btn color="secondary" outlined elevation="0" @click="closeDialog">Close</v-btn>
              </v-card-title>
              <v-card-text>
                <v-form lazy-validation @submit.prevent="() => {}">
                  <v-text-field v-model="formData.title" label="Title *"></v-text-field>
                  <v-textarea v-model="formData.comments" label="Comments *"></v-textarea>
                  <v-select v-model="formData.selectedPriority" :items="priorityOptions" label="Select Priority *">
                  </v-select>
                  <v-select v-model="formData.selectedStatus" :items="statusOptions" label="Select Status *"></v-select>
                  <v-text-field v-model="formData.claimant" label="Claimant *"></v-text-field>
                  <v-text-field v-model="formData.assigned" label="Assigned To (optional)"></v-text-field>
                  <v-text-field v-model="formData.location" label="Location (optional)"></v-text-field>
                  <v-btn class="mt-7" color="primary" :disabled="note_cannotSave" @click="addNote()"> Add </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- Add Note Button, Modal and Form end -->
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mdiMessagePlus, mdiCircleSlice8, mdiPlayCircle, mdiTextBoxMultipleOutline } from "@mdi/js"
import { mapState, mapActions } from "vuex"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import store from "@/store"
import urlHelpers from "@/mixins/urlHelpers"
import globalHelpers from "@/mixins/globalHelpers"

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: "ServiceOverview",

  mixins: [urlHelpers, globalHelpers],

  data() {
    return {
      currentTab: "",
      route_settings: {},
      icons: {
        mdiMessagePlus,
        mdiCircleSlice8,
        mdiPlayCircle,
      },
      stopsOnRouteTableColumns: [
        { text: "Name", value: "stop_name" },
        { text: "Code", value: "stop_id" },
        { text: "Zone", value: "zone_id" },
        {
          text: "Preview",
          value: "config",
          align: "center",
          sortable: false,
        },
      ],
      sqCreated: "",
      sqClaimant: "",
      sqPriority: null,
      noteSortBy: "note_id",
      menu: false,
      // Notes Table Headers
      noteHeaders: [
        { text: "Note ID", value: "note_id" },
        { text: "Title", value: "title" },
        { text: "Priority", value: "priority" },
        { text: "Status", value: "status" },
        { text: "Claimant", value: "claimant" },
        { text: "Created", value: "convertCreatedDate" },
      ],
      priorityItems: [
        {
          text: "Low",
          value: "Low",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "High",
          value: "High",
        },
        {
          text: "Urgent",
          value: "Urgent",
        },
      ],
      is_loading: true,
      formData: {
        selectedType: "Route",
        selectedForeignId: null,
        title: "",
        comments: "",
        selectedPriority: null,
        selectedStatus: null,
        claimant: "",
        assigned: "",
        location: "",
      },
      dialog: false,
      noteSortDesc: false, // true for descending order, false for ascending order
    }
  },
  computed: {
    ...mapState({
      route: state => state.selected_route,
      stops: state => state.gtfs.stops,
      notes: state => state.gtfs.notes,
    }),
    tabs() {
      const tabs = [
        { title: "Configuration", icon: mdiMessagePlus },
        // { title: 'Stop Preview', icon: mdiCircleSlice8 },
      ]

      if (this.canNotes) {
        tabs.push({ title: "Notes", icon: mdiTextBoxMultipleOutline })
      }

      return tabs
    },
    relatedStops() {
      return this.stops.filter(stop => this.route.stops.includes(stop.stop_id))
    },
    canNotes() {
      return this.$auth.hasScope("notes")
    },
    // Notes Filtering
    filteredNotes() {
      return this.notes.filter(note => {
        let include = true
        if (include && this.sqCreated !== "" && this.sqCreated !== null) {
          // eslint-disable-next-line no-undef
          const date = dayjs(this.formatDate(note.created, "en-NZ"), "D/MM/YYYY, h:mm:ss a")
          include = date.format("YYYY-MM-DD") === this.sqCreated
        }
        if (include && this.sqClaimant !== "" && this.sqClaimant !== null) {
          include = note.claimant.toLowerCase().includes(this.sqClaimant.toLowerCase())
        }
        if (include && this.sqPriority !== "" && this.sqPriority !== null) {
          include = note.priority === this.sqPriority
        }

        return include
      })
    },
    convertCreatedDate() {
      return function (item) {
        return item.created ? this.formatDate(item.created, "en-NZ") : "Unknown"
      }
    },
    priorityOptions() {
      return ["Low", "Medium", "High", "Urgent"]
    },
    statusOptions() {
      return ["Open", "Pending", "On Hold", "Closed"]
    },
    note_cannotSave() {
      return (
        !this.formData.title ||
        !this.formData.comments ||
        !this.formData.selectedPriority ||
        !this.formData.selectedStatus ||
        !this.formData.claimant
      )
    },
  },
  created() {
    this.route_settings = JSON.parse(JSON.stringify(this.route.config))

    this.formData.selectedForeignId = {
      title: `${this.route.route_short_name} - ${this.route.route_long_name}`,
      value: this.route.route_id,
    }

    this.is_loading = true
    store
      .dispatch("getNotes", {
        type: "Route",
        foreign_id: this.route.route_id,
      })
      .then(() => {
        this.is_loading = false
      })
  },
  methods: {
    ...mapActions(["TTSpreview"]),
    getFullStopPreview(stopId) {
      store.dispatch("fullStopPreview", stopId)
    },
    saveRouteConfig() {
      store.dispatch("saveRouteConfig", this.route_settings)
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    async addNote() {
      try {
        const { selectedForeignId, title, comments, selectedPriority, selectedStatus, claimant } = this.formData
        // if fields filled out
        if (
          title.trim() !== "" &&
          comments.trim() !== "" &&
          selectedPriority &&
          selectedStatus !== null &&
          claimant.trim() !== ""
        ) {
          await this.$store.dispatch("addNote", { formData: this.formData })
          store.commit("SET_SNACKBAR", {
            text: `The note ${title} on ${selectedForeignId.title} has been added`,
            color: "success",
          })
          // Reset the form fields after successful submission
          this.formData = {
            selectedType: "Route",
            selectedForeignId: {
              title: `${this.route.route_short_name} - ${this.route.route_long_name}`,
              value: this.route.route_id,
            },
            title: "",
            comments: "",
            selectedPriority: null,
            selectedStatus: null,
            claimant: "",
            assigned: "",
            location: "",
          }
          this.dialog = false
        }
      } catch (error) {
        console.error("error:", error)
        store.commit("SET_SNACKBAR", {
          text: `The note ${this.formData.title} on ${this.formData.selectedForeignId.title} could not be added`,
          color: "error",
        })
      }
    },

    /**
     * Overrides default sorting by table headers.
     */
    customSort(items, sortBy, sortDesc) {
      const sortByField = sortBy[0] ?? ""
      const sortDescending = sortDesc[0] ?? false

      items.sort((a, b) => {
        let sortA = null
        let sortB = null

        // Handle sorting based on clicked header column
        switch (sortByField) {
          case "convertCreatedDate": {
            // We don't want to use convertCreatedDate as it can return "Unknown"
            sortA = new Date(a?.created)
            sortB = new Date(b?.created)

            break
          }
          case "priority": {
            // Order by priority not alphabetically
            const priorityOrder = {
              urgent: 1,
              high: 2,
              medium: 3,
              low: 4,
            }

            sortA = priorityOrder[a.priority?.toLowerCase()] ?? 5
            sortB = priorityOrder[b.priority?.toLowerCase()] ?? 5

            break
          }
          default: {
            // Default sorting
            sortA = a[sortBy]
            sortB = b[sortBy]

            break
          }
        }

        if (sortA < sortB) {
          return -1
        }

        if (sortA > sortB) {
          return 1
        }

        return 0
      })

      // Reverse order if header clicked again
      if (sortDescending) {
        items.reverse()
      }

      return items
    },
  },
}
</script>
